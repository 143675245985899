import { AllRouters } from "./_components/routes/AllRouters";


import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'animate.css';
import './_assets/css/style.css';
import './_assets/css/main.css';
import './_assets/css/Custom.css';
import './_assets/css/responsive.css';


const App = () => {
  return (<AllRouters />);
}

export default App;
