import React from 'react';
import logo from '../../_assets/images/favicon.png';

export const MainLoader = ({ show }) => {
  return (
    <div className={`loading ${show ? 'show' : ''}`}>
      <div className="spinner-box">
        <div className="configure-border-1">
          <div className="configure-core"></div>
        </div>
        <div className="configure-border-2">
          <div className="configure-core"></div>
        </div>
        <img src={logo} className='load-img' width="80" height="80" />
      </div>
    </div>
  )
}
