import { HIDE_SHOP_PAGE, OFF_LOADING, ON_LOADING, SHOW_SHOP_PAGE } from "../constants/generalConstants";

const general = {
    loading: false,
    shopPage: false
}

export const generalReducers = (state = general, action) => {
    switch (action.type) {
        case ON_LOADING:
            return {
                ...state,
                loading: true
            };
        case OFF_LOADING:
            return {
                ...state,
                loading: false
            }
        case SHOW_SHOP_PAGE:
            return {
                ...state,
                shopPage: true
            }
        case HIDE_SHOP_PAGE:
            return {
                ...state,
                shopPage: false
            }
        default:
            return state
    }
}