import { SET_APPLICATION_INFO, SET_HEALTHCARE_PRACTITIONER, SET_MAILING_SHIPPING_INFO, SET_RESIDENCE_INFO, SET_RESPONSIBLE_APPLICANT } from "../constants/medicalDataConstants"


const initialState = {
    patientname: '',
    patientemail: '',
    patientdate_of_birth: '',
    patientgender: '',

    residence_address1: '',
    residence_address2: '',
    residence_city: '',
    residence_province: '',
    residence_postal_code: '',
    mobile_number: '',
    fax: '',

    mailing_address1: '',
    mailing_address2: '',
    mailing_city: '',
    mailing_province: '',
    mailing_postal_code: '',

    shipping_address1: '',
    shipping_address2: '',
    shipping_city: '',
    shipping_province: '',
    shipping_postal_code: '',

    resposible_name: '',
    responsible_date_of_birth: '',
    responsible_gender: '',

    practitioner_name: '',
    clinic_name: '',
    practitioner_address1: '',
    practitioner_address2: '',
    practitioner_city: '',
    practitioner_province: '',
    practitioner_postal_code: '',
    practitioner_mobile_number: '',
    practitioner_fax: '',
    practitioner_email: '',

    accepted: false,

    // extra params
    practionerOn: false,
    shipcheck: ''
}

export const medicalDataReducers = (state = initialState, action) => {

    switch (action.type) {
        case SET_APPLICATION_INFO:
            return {
                ...state,
                patientname: action.data.given_name + ' ' + action.data.surname,
                patientemail: action.data.email,
                patientdate_of_birth: action.data.dob,
                patientgender: action.data.gender,
            };

        case SET_RESIDENCE_INFO:
            return {
                ...state,
                residence_address1: action.data.address1,
                residence_address2: action.data.address2,
                residence_city: action.data.city,
                residence_province: action.data.province,
                residence_postal_code: action.data.postal_code,
                mobile_number: action.data.mobile_number,
                fax: action.data.fax,
            }

        case SET_MAILING_SHIPPING_INFO:

            let updateData = {
                ...state,
                mailing_address1: action.data.m_address1,
                mailing_address2: action.data.m_address2,
                mailing_city: action.data.m_province,
                mailing_province: action.data.m_city,
                mailing_postal_code: action.data.m_postal_code,
                shipcheck: action.data.shipcheck
            }

            if (action.data.shipcheck === 'residence_address') {
                updateData = {
                    ...updateData,
                    shipping_address1: state.residence_address1,
                    shipping_address2: state.residence_address2,
                    shipping_city: state.residence_city,
                    shipping_province: state.residence_province,
                    shipping_postal_code: state.residence_postal_code,
                }
            } else if (action.data.shipcheck === 'mailing_address') {
                updateData = {
                    ...updateData,
                    shipping_address1: state.mailing_address1,
                    shipping_address2: state.mailing_address2,
                    shipping_city: state.mailing_city,
                    shipping_province: state.mailing_province,
                    shipping_postal_code: state.mailing_postal_code,
                }
            }

            return updateData;

        case SET_RESPONSIBLE_APPLICANT:
            return {
                ...state,
                resposible_name: action.data.r_given_name + + action.data.r_surname,
                responsible_date_of_birth: action.data.r_dob,
                responsible_gender: action.data.r_gender,
            };

        case SET_HEALTHCARE_PRACTITIONER:

            let practitionerData = {
                ...state,
                practitioner_name: action.data.pra_given_name + ' ' + action.data.pra_surname,
                clinic_name: action.data.pra_business_name,
                practitioner_address1: action.data.pra_address1,
                practitioner_address2: action.data.pra_address2,
                practitioner_city: action.data.pra_city,
                practitioner_province: action.data.pra_province,
                practitioner_postal_code: action.data.pra_postal_code,
                practitioner_mobile_number: action.data.pra_mobile_number,
                practitioner_fax: action.data.pra_fax,
                practitioner_email: action.data.pra_email,
            }

            if (state.shipcheck == 'practitioner_address') {
                practitionerData = {
                    ...practitionerData,
                    shipping_address1: action.data.pra_address1,
                    shipping_address2: action.data.pra_address2,
                    shipping_city: action.data.pra_city,
                    shipping_province: action.data.pra_province,
                    shipping_postal_code: action.data.pra_postal_code,
                }
            }

            return practitionerData;

        default:
            return state;
    }
}