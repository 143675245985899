import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { MainLoader } from '../common/MainLoader';
import { VerifyCheck } from './VerifyCheck';

const FrontHome = lazy(() => import('../../_pages/FrontHome'));
const FrontAbout = lazy(() => import('../../_pages/FrontAbout'));
const FrontContact = lazy(() => import('../../_pages/FrontContact'));
const FrontProducts = lazy(() => import('../../_pages/FrontProducts'));
const FrontShop = lazy(() => import('../../_pages/FrontShop'));
const ErrorPage = lazy(() => import('../../_pages/ErrorPage'));
const UserFrontSide = lazy(() => import('../../_pages/UserFrontSide'));
const FrontMedical = lazy(() => import('../../_pages/FrontMedical'));
const Verification = lazy(() => import('../../_pages/Verification'));
const FrontProductDetails = lazy(() => import('../../_pages/FrontProductDetails'));
const MedicalForm = lazy(() => import('../../_components/pages/MedicalForm'));

export const AllRouters = () => {
  return (
    <Routes>
      <Route path="/" element={<Suspense fallback={<MainLoader />}><VerifyCheck><UserFrontSide /></VerifyCheck></Suspense>}>
        <Route index element={<Suspense fallback={<MainLoader />}><FrontHome /></Suspense>} />
        <Route path="/about" element={<Suspense fallback={<MainLoader />}><FrontAbout /></Suspense>} />
        <Route path="/contact" element={<Suspense fallback={<MainLoader />}><FrontContact /></Suspense>} />
        <Route path="/products" element={<Suspense fallback={<MainLoader />}><FrontProducts /></Suspense>} />
        <Route path="/medical" element={<Suspense fallback={<MainLoader />}><FrontMedical /></Suspense>} />
        <Route path="/medical-form" element={<Suspense fallback={<MainLoader />}><MedicalForm /></Suspense>} />
        <Route path="/product/:proName" element={<Suspense fallback={<MainLoader />}><FrontProductDetails /></Suspense>} />
      </Route>
      <Route path="*" element={<Suspense fallback={<MainLoader />}><ErrorPage /></Suspense>} />
      <Route path="/shop" element={<Suspense fallback={<MainLoader />}><FrontShop /></Suspense>} />
      <Route path="/verification" element={<Suspense fallback={<MainLoader />}><Verification /></Suspense>} />
    </Routes>
  )
}
