import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const VerifyCheck = ({ children }) => {

    let userage = localStorage.getItem('UserAge');
    let location = useLocation();

    if (userage && userage >= 18) {
        // if (location.pathname == '/verification') {
        //     return <Navigate to="/" replace={true} />;
        // }
        return children;
    }

    return <Navigate to="/verification" replace={true} />;
}