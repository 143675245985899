import { SAVE_CONTACT_INFORMATION, RESET_CONTACT_INFORMATION } from "../constants/contactConstants";

const contactInfo = {
    'sender_email_address': '',
    'sender_name': '',
    'sender_subject': '',
    'sender_related': '',
    'sender_message': '',
}

export const contactReducers = (state = contactInfo, action) => {
    switch (action.type) {
        case SAVE_CONTACT_INFORMATION:
            return {
                ...state,
                'sender_email_address': action.payload?.sender_email_address,
                'sender_name': action.payload?.sender_name,
                'sender_subject': action.payload?.sender_subject,
                'sender_related': action.payload?.sender_related,
                'sender_message': action.payload?.sender_message,
            };
        case RESET_CONTACT_INFORMATION:
            return contactInfo;

        default:
            return state;
    }
}