import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';

import { generalReducers } from './reducers/generalReducers';
import { contactReducers } from './reducers/contactReducers';
import { medicalDataReducers } from './reducers/medicalDataReducers';

const reducer = combineReducers({
    general: generalReducers,
    contact: contactReducers,
    medical: medicalDataReducers
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configStore = (initialState = {}) => {

    const middleware = [thunk];

    const store = createStore(
        reducer,
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware)
        )
    );

    return store;
}

export const store = configStore();